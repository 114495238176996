import { useLocation } from '@reach/router';
import { message } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import SharedHeader from '../components/general/components/SharedHeader';
import authenticateUser from '../newApi/authenticate';
import { loginSuccessful } from '../redux/actions/user-actions';
import { routes } from '../route';

// markup
const Login = (props) => {
  const location = useLocation();
  message.config({
    top: 10,
    duration: 2,
    maxCount: 1,
  });

  useEffect(() => {
    let query = queryString.parse(location.search);
    // setType(get(query, "type"));
    authenticateUser(query)
      .then((res) => {
        // message.success('test');
        // console.log("res", res);
        // let userData = get(res, "data.user");
        // console.log("user", get(res, "data.user"));

        props.loginSuccessful(
          get(res, "data.user"),
          query.accessKey,
          query.timestamp,
        );
        window.location.assign("/");
      })
    .catch((err) => {
        //Redirect To Error Page
        console.log(err);
        message.error(err?.message);

        window.location.href = routes.myits.to();
      });
  }, []);

  return (
    <React.Fragment>
      <SharedHeader />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
